import React from 'react'
import {graphql, Link, StaticQuery} from 'gatsby'
import Container from "./container";
import HeroBlue from '../assets/hero-blue.svg'
import HeroRed from '../assets/hero-red.svg'
import DecalOne from "../assets/decal1.svg";
import DecalTwo from "../assets/decal2.svg";
import GradientImage from "./gradient-image";
import ScrollDownButton from './scroll-down-button'
import HeroLayout from "./heroLayout";

const heroQuery = graphql`
query HeroQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
        heroes {
            title
            text
            color
            image {
                ...MainImage
            }
            mobileImage: image {
                ...MobileMainImage
            }
        }
    }
}
`


const Hero = ({title}) => {

    return (
        <StaticQuery
            query={heroQuery}
            render={({site}) => {
                let hero = site.heroes.filter(h => h.title === title).pop()

                return (<HeroLayout {...hero} />)
            }}/>
    )
}

export default Hero

import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import SEO from '../components/seo'
import Card from '../components/card'
import Hero from '../components/hero'
import Wave from "../components/svg/wave";
import WaveReverse from "../components/svg/wave-reverse";
import PortableText from "../components/portableText";
import {getPostUrl} from "../lib/helpers";
import Wrapper from "../components/wrapper";


export const query = graphql`
query TagsPageQuery($tag: String!) {
    posts: allSanityPost(
        filter: {tags: {eq: $tag}}
        sort: {fields: [publishedAt], order: DESC}
    ) {
        edges {
            node {
                publishedAt
                title
                slug {
                    current
                }
                _rawExcerpt(resolveReferences: {maxDepth: 5})
                mainImage {
                    ...MainImage
                }
            }
        }
    }
}
`

class TagsPage extends React.Component {
    constructor(props) {
        super(props);
        const {data, errors, pageContext} = props
        this.state = {
            frontPage: (data || {}).frontPage,
            posts: (data || {}).posts,
            tag: pageContext.tag,
        }
    }

    render() {
        return (
            <>
                <SEO
                    title='Blogg'
                />
                <Wrapper primaryColor='brand-red' secondaryColor='gray-200'>
                    <Container className='relative'>
                        <div
                            className='-mt-16 md:-mt-24 lg:-mt-32 absolute top-0 z-50 inline-flex text-xl md:text-4xl font-heading shadow-lg'>
                            <div className='p-2 bg-brand-red text-white'>Tagget med</div>
                            <div className='p-2 bg-brand-red text-white font-bold'>{this.state.tag}</div>
                        </div>
                        <div className=''>
                            <div className='grid gap-10 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 pb-4'>
                                {this.state.posts && this.state.posts.edges.map(({node}, index) => (
                                    <Card
                                        key={`post-${index}`}
                                        url={getPostUrl(node.publishedAt, node.slug)}
                                        title={node.title}
                                        text={(<PortableText blocks={node._rawExcerpt}/>)}
                                        image={node.mainImage.asset.fluid}
                                        readMoreText='Les mer'
                                        date={node.publishedAt}
                                    />
                                ))}
                            </div>
                        </div>
                    </Container>
                </Wrapper>
            </>
        )
    }
}

export default TagsPage

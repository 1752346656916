import React from 'react'

const Wrapper = ({children, primaryColor, secondaryColor, hasImage = false, hasTop = true, hasBottom = true, hasHero = false}) => {
    return (
        <div className={'overflow-y-hidden'}>
            <div className={`md:pt-12 pb-12 bg-${secondaryColor}`}>
                {children}
            </div>
        </div>
    )
}

export default Wrapper

import DecalOne from "../assets/decal1.svg";
import DecalTwo from "../assets/decal2.svg";
import GradientImage from "./gradient-image";
import Container from "./container";
import ScrollDownButton from "./scroll-down-button";
import React from "react";

const HeroLayout = ({color, image = null, mobileImage = null, title, text}) => {

    let backgroundColor, decal;
    if (color === 'blue') {
        backgroundColor = 'bg-brand-blue-light'
        decal = (
            <div
                className='absolute left-0 top-0 z-20 -mt-32 w-3/4 max-w-hero h-full md:-mt-32 overflow-hidden md:overflow-visible'>
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 496.195 828.088" className='md:h-full w-full md:w-auto -mt-32 md:mt-32'>
                    <path className="fill-current text-brand-blue-blend"
                          d="M667.829,1085.426a198.125,198.125,0,0,0,80.142-31.694q50.846-33.875,50.825-98.338,0-62.743-78.384-102.775-18.85-10.521-52.583-22.37V548.469H842.1c57.97,20.75,119.882,44.091,137.536,52.334,43.2,20.16,71.915,40.588,99.142,67.188,62.883,61.58,84.246,132.727,84.246,231.232q0,238.4-138.99,353.1-141.53,116.77-356.206,123.22Z"
                          transform="translate(-667.329 -547.969)"/>
                </svg>
            </div>
        )
    }

    if (color === 'red') {
        backgroundColor = 'bg-brand-red-lighter'
        decal = (
            <div className='absolute left-0 top-0 z-20 right-0 md:flex w-auto h-auto justify-center'>
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 1558.121 227.965" className='w-full'>
                    <g transform="translate(-181 -113.5)">
                        <path className="text-brand-red-blend fill-current"
                              d="M351-1823.232v-25.693H606.164v25.693A127.582,127.582,0,0,1,478.582-1695.65,127.582,127.582,0,0,1,351-1823.232Z"
                              transform="translate(-170 1962.925)"/>
                        <path className="text-brand-red-blend fill-current"
                              d="M682.821-1367.559l52.8-96.953c.27-.5.545-1,.82-1.489h767.512q-29.815,72.947-87.793,120.8-128.641,106.167-327.67,106.16Q871.074-1239.035,682.821-1367.559Z"
                              transform="translate(-170 1580)"/>
                        <path className="text-brand-red-blend fill-current"
                              d="M1777.461-1695.65c-68.171,0-123.973-54.587-123.973-121.312v-31.959h255.133v31.959c0,66.725-55.8,121.312-123.961,121.312Z"
                              transform="translate(-170 1962.921)"/>
                    </g>
                </svg>
            </div>
        )
    }


    if (image) {
        return (
            <>
                <div className='bg-brand-blue md:bg-transparent relative mb-32'>
                    <div className="relative z-20 max-w-sm">
                        <img src={DecalOne} className='absolute hidden md:block'/>
                        <img src={DecalTwo}
                             className='absolute top-0 mix-blend-mode md:mt-200 w-12 md:w-auto'/>
                    </div>
                    <GradientImage
                        className='z-10'
                        image={image.asset.fluid}
                        mobileImage={mobileImage.asset.fluid}/>
                    <div
                        className='md:bg-transparent relative -mt-12 md:mt-0  md:absolute z-50 inset-0 flex flex-col md:flex-row md:items-end md:justify-center md:pb-40'>
                        <Container>
                            <div className='md:px-130'>
                                <h2 className='text-3xl text-center md:text-left md:text-5xl text-white mb-8 font-semibold leading-tight font-heading max-w-3xl tracking-tighter'>
                                    {title}
                                </h2>
                                <p className='text-base text-center md:text-left text-white md:mb-12 max-w-xl leading-normal'>
                                    {text}
                                </p>
                            </div>
                        </Container>
                        <Container className='md:absolute bottom-0'>
                            <div
                                className="md:-ml-2 px-10 w-full md:w-1/3 md:absolute bottom-0 left-0 flex justify-center">
                                <div
                                    className='flex md:hidden items-center justify-center transform translate-y-61px'>
                                    <ScrollDownButton className='md:-mb-61px'/>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            </>
        )
    }
    return (

        <div
            className={`${backgroundColor} text-white flex flex-col md:flex-row items-end md:justify-center relative z-20 pb-24 md:pb-40 md:mb-24 md:min-h-650`}>
            {decal}
            <Container className='relative z-30'>
                <div className="px-4 md:px-130 pt-24 md:pt-0">
                    <div className='w-full text-center md:text-left' style={{maxWidth: '650px'}}>
                        <h2 className='text-3xl text-center md:text-left md:text-5xl text-white mb-8 font-semibold leading-tight font-heading max-w-3xl tracking-tighter'>{title}</h2>

                        <p className='text-base leading-normal'>{text}</p>
                    </div>
                </div>
            </Container>
            <Container className='md:absolute bottom-0 flex-end md:flex-start'>
                <div
                    className="md:-ml-2 px-10 w-full md:w-1/3 md:absolute bottom-0 left-0 flex justify-center">

                    <div
                        className='flex md:hidden items-center justify-center md:transform md:translate-y-61px pt-12 md:pt-0 pb-24 md:pb-40 md:pb-0'>
                        <ScrollDownButton/>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default HeroLayout;